import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import SearchForm from "../../components/searchForm/SearchForm";
import { useAppSelector } from "../../redux/hooks";
import "../../components/searchSidebar/SearchSidebar.scss";
import Masthead from "../../components/masthead/Masthead";
import SearchBreadcrumbs from "../../components/searchBreadcrumbs/SearchBreadcrumbs";
import { Link, useNavigate, useParams } from "react-router-dom";
import searchApiService from "../../services/apis/SearchApiService";
import { useEffect, useState } from "react";
import { TemplateAttribute } from "../../interfaces/TemplateAttribute";
import Response from "../../services/apis/Response";
import { PostTemplateAttributesResponse } from "../../interfaces/PostTemplateAttributesResponse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TemplateAttributeValue } from "../../interfaces/TemplateAttributeValue";
import SearchResultLoader from "../../components/searchLoader/SearchResultLoader";
import { TeaserCard, TeaserCardActions, TeaserCardDescription, TeaserCardTitle } from "@rockwell-automation-inc/ra-meatball";
import SearchSidebarLoader from "../../components/searchLoader/SearchSidebarLoader";
import { defaultImageOnError, documentUrl, findNestedProduct } from "../../helpers/Utils";
import defaultImage from "../../assets/Image_Unavailable.svg";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import PageSelector from "../../components/common/PageSelector/PageSelector";
import ROUTES from "../../constants/Routes";
import { FeatureFlag, selectCatalog, usePSAppSelector, CatalogTemplate, CatalogProduct } from "platform-services";
import FEATURES from "../../constants/FeatureFlags";
import ProductAssistantTool from "../../components/ProductAssistantTool/ProductAssistantTool";
import EllipsisTooltip from "./EllipsisTooltip";

import "../../components/ProductAssistantTool/ProductAssistantTool.scss";
import { FeatureFlagHelperClass } from "platform-services/dist/util/FeatureFlag";
const FamilySearch = () => {
  const cardHeight = new FeatureFlagHelperClass(window.featureFlags).canDisplay(FEATURES.PI26_BP02_CONFIGURATOR_ENABLED) ? "470px" : "420px";
  const { catalogGroupId } = useParams();
  const [templates, setTemplates] = useState<PostTemplateAttributesResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const catalog = usePSAppSelector(selectCatalog);
  const [page, setPage] = useState(1);
  const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(25);
  const theme = useTheme();
  const [breadcrumb, setBreadcrumb] = useState<Array<CatalogProduct>>([]);
  const navigate = useNavigate();
  const FIRST_ROW_THRESHOLD = 4;
  const SECOND_ROW_THRESHOLD = 8;
  const [isOpen, setIsOpen] = useState(false);
  const fetchTemplateAttributeData = async (catalogGroupId: string, templates: PostTemplateAttributesResponse | null) => {
    setIsLoading(true);
    try {
      const result = await searchApiService.PostTemplateAttributes({
        catalogGroup: catalogGroupId,
        attributes: templates?.attributes || [],
      });
      setIsLoading(false);
      return new Response(result).getData();
    } catch {
      setIsLoading(false);
      throw new Error();
    }
  };

  const products = catalogGroupId
    ? findNestedProduct(catalog, catalogGroupId)
        ?.childGroups.map(child => {
          return child.templates.filter(i => templates?.matchedTemplates.includes(i.templateId));
        })
        .flat()
    : [];

  const handleClearAll = async () => {
    const newAttributes: TemplateAttribute[] =
      templates?.attributes.map(attribute => {
        return {
          ...attribute,
          values: attribute.values.map(value => {
            return {
              ...value,
              isSelected: false,
            };
          }),
        };
      }) || [];
    if (templates && catalogGroupId) {
      setTemplates(
        await fetchTemplateAttributeData(catalogGroupId, {
          ...templates,
          attributes: newAttributes,
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (catalogGroupId && !templates) {
        const fetchedTemplates = await fetchTemplateAttributeData(catalogGroupId, templates);
        console.log("setting templates", templates, fetchedTemplates);
        setTemplates(x => fetchedTemplates);
      }
    })();
  }, [catalogGroupId, templates]);

  const handleCheck = async (attribute: TemplateAttribute, value: TemplateAttributeValue, checked: boolean) => {
    const newAttributes: TemplateAttribute[] = templates
      ? templates?.attributes.map(_attribute => {
          if (_attribute.id === attribute.id) {
            return {
              ...attribute,
              values: _attribute.values.map(_value => {
                if (_value.name === value.name) {
                  return {
                    ..._value,
                    isSelected: checked,
                  };
                }
                return _value;
              }),
            };
          }
          return _attribute;
        })
      : [];
    if (templates && catalogGroupId) {
      setTemplates(
        await fetchTemplateAttributeData(catalogGroupId, {
          ...templates,
          attributes: newAttributes,
        })
      );
    }
  };

  interface TemplateGroupProps {
    attribute: TemplateAttribute;
  }

  const TemplateGroup: React.FC<TemplateGroupProps> = ({ attribute }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const [attributeValues, setAttributeValues] = useState<string[]>(() => attribute.values.filter(_val => _val.isSelected).map(_val => _val.name));

    useEffect(() => {
      if (attribute.values.length === attributeValues.length) {
        setAttributeValues([...attributeValues, "All"]);
      }
    }, []);

    if (attribute.name === "Cost Value" || attribute.name === "Features Value") {
      const updateFacet = async (value: string[]) => {
        const newAttributes: TemplateAttribute[] = templates
          ? templates?.attributes.map(_attribute => {
              if (_attribute.id === attribute.id) {
                return {
                  ...attribute,
                  values: _attribute.values.map(_value => {
                    return {
                      ..._value,
                      isSelected: _value.isSelectable ? value.includes(_value.name) : false,
                    };
                  }),
                };
              }
              return _attribute;
            })
          : [];
        if (templates && catalogGroupId) {
          setTemplates(
            await fetchTemplateAttributeData(catalogGroupId, {
              ...templates,
              attributes: newAttributes,
            })
          );
        }
      };
      const handleSelectChange = (event: SelectChangeEvent<typeof attributeValues>) => {
        const {
          target: { value },
        } = event;

        // the incoming values from select options which are checked.
        const incomingValues = typeof value === "string" ? value.split(",") : value;

        // all the values values in selection option
        const allAttributeValues = attribute.values.filter(_val => _val.isSelectable).map(_val => _val.name);

        let newAttributeValues: string[] = [];

        if (value.includes("All")) {
          if (attributeValues.includes("All")) {
            newAttributeValues = incomingValues;
            newAttributeValues = newAttributeValues.filter(_val => _val !== "All");
          } else {
            newAttributeValues = allAttributeValues;
          }
        } else {
          if (attributeValues.includes("All") && incomingValues.length === allAttributeValues.length) {
            newAttributeValues = [];
          } else {
            newAttributeValues = incomingValues;
          }
        }
        updateFacet(newAttributeValues);
      };
      //Changed the Investment & Features to be dropdowns instead of checkboxes.
      const AttributeLabel = attribute.name === "Features Value" ? "Investment" : "Features & Performance";
      return (
        <FormControl sx={{ m: 1, width: "100%" }}>
          <InputLabel id="demo-multiple-checkbox-label">{AttributeLabel}</InputLabel>
          <Select
            sx={{
              ".MuiSelect-icon": {
                fontSize: "40px",
                width: "30px",
                height: "40px",
              },
            }}
            color="primary"
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={attributeValues}
            onChange={handleSelectChange}
            renderValue={selected => selected.join(", ")}
            input={<OutlinedInput label={AttributeLabel} />}>
            <MenuItem
              value={"All"}
              // disabled={attribute.values.some((_val)=>!_val.isSelectable)}
              sx={{
                fontSize: 14,
                margin: "4px",
                padding: "0px 4px 0px 8px",
              }}>
              <Checkbox
                checked={attributeValues.indexOf("All") > -1}
                // disabled={attribute.values.some((_val)=>!_val.isSelectable)}
              />
              <ListItemText primary={"All"} />
            </MenuItem>
            {attribute.values
              .filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((value: TemplateAttributeValue, index: number) => (
                <MenuItem disabled={!value.isSelectable} value={value.name} sx={{ fontSize: 14, margin: "4px", padding: "0px 4px 0px 8px" }}>
                  <Checkbox disabled={!value.isSelectable} checked={attributeValues.indexOf(value.name) > -1} />
                  <ListItemText primary={value.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`group-${attribute.name}-content`}
          id={`group-${attribute.name}-header`}
          style={{ fontWeight: "bold" }}
          sx={{ borderBottom: "1px solid #ccc", borderRadius: "4px" }}>
          {attribute.name}
        </AccordionSummary>
        <AccordionDetails
          id={`group-${attribute.name}-content`}
          sx={{
            maxHeight: "14rem",
            overflow: "auto",
            padding: "10px",
          }}>
          {attribute.values.length >= 20 && (
            // <SearchBar
            //   index={index}
            //   searchTerm={searchTerm}
            //   handleChange={handleChange}
            // />
            <></>
          )}
          {attribute.values
            .filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((value: TemplateAttributeValue, index: number) => {
              return (
                <FormControl key={index} fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheck(attribute, value, event.target.checked)}
                        checked={value.isSelected}
                        disabled={!value.isSelectable}
                        sx={{ padding: "5px" }}
                      />
                    }
                    label={
                      <Box display="flex" style={{ fontSize: ".9rem" }}>
                        <Typography color="black">{value.name}</Typography>
                      </Box>
                    }
                  />
                </FormControl>
              );
            })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const Cost: React.FC<{ cost: number }> = ({ cost }) => {
    const inner = "#EDF7ED";
    const outer = "#468C4A";
    const disabled = "#BFEEA0";
    return (
      <Box
        sx={{
          display: "flex",
          borderRadius: "10px",
          backgroundColor: inner,
          border: `1px solid ${outer}`,
          width: "fit-content",
        }}>
        {[...Array(3)].map((_, index: number) => {
          return <AttachMoneyOutlinedIcon fontSize="large" sx={{ color: cost >= index + 1 ? outer : disabled }} />;
        })}
      </Box>
    );
  };

  const Features: React.FC<{ features: number }> = ({ features }) => {
    const inner = "#E5F6FD";
    const outer = "#0288D1";
    const disabled = "#BBDEFB";
    return (
      <Box
        sx={{
          display: "flex",
          borderRadius: "10px",
          backgroundColor: inner,
          border: `1px solid ${outer}`,
          width: "fit-content",
        }}>
        {[...Array(3)].map((_, index: number) => {
          return <FlashOnOutlinedIcon key={index} fontSize="large" sx={{ color: features >= index + 1 ? outer : disabled }} />;
        })}
      </Box>
    );
  };

  const Results = () => {
    return (
      <Typography sx={{ fontWeight: "bold" }}>
        Showing Results:{` `}
        {(page - 1) * numberOfResultsPerPage + 1} -
        {(page - 1) * numberOfResultsPerPage + numberOfResultsPerPage > (products?.length || 0)
          ? products?.length || 0
          : (page - 1) * numberOfResultsPerPage + numberOfResultsPerPage}
        {` `}/{` `}
        {products?.length.toLocaleString("US")}
      </Typography>
    );
  };

  const isClearAll = () => {
    return templates?.attributes.find(x => x.values.find(x => x.isSelected));
  };

  const Chips = () => {
    return (
      <>
        {templates?.attributes.map(attribute => {
          return (
            <>
              {attribute.values
                .filter(i => i.isSelected)
                .map((value, index) => {
                  return (
                    <Stack key={index} sx={{ display: "inline-grid", mb: 1, ml: 1, mt: 1 }} direction="row" spacing={1}>
                      <Chip
                        sx={{ background: theme.palette.primary.main }}
                        label={<Typography color="white">{value.name}</Typography>}
                        onDelete={(event: React.ChangeEvent<HTMLInputElement>) => console.log(event)}
                        // handleCheck(attribute, value, event.target.checked)
                        color="secondary"
                        size="small"
                      />
                    </Stack>
                  );
                })}
            </>
          );
        })}
      </>
    );
  };

  const currentProduct = findNestedProduct(catalog, templates?.catalogGroup || "", setBreadcrumb);

  const checkForHelpMeChooseAddBtn = (index: number) => {
    const totalproducts = products?.length || 0;
    if (totalproducts >= SECOND_ROW_THRESHOLD && index == SECOND_ROW_THRESHOLD - 1) return true;
    else if (totalproducts == FIRST_ROW_THRESHOLD && index == FIRST_ROW_THRESHOLD - 1) {
      return true;
    } else if (
      totalproducts > FIRST_ROW_THRESHOLD &&
      ((totalproducts == FIRST_ROW_THRESHOLD + 1 && index == FIRST_ROW_THRESHOLD) ||
        (totalproducts == FIRST_ROW_THRESHOLD + 2 && index == FIRST_ROW_THRESHOLD + 1) ||
        (totalproducts == SECOND_ROW_THRESHOLD - 1 && index == SECOND_ROW_THRESHOLD - 2))
    )
      return true;
    else return false;
  };

  const HelpMeChoose = () => {
    return (
      <Grid item xs={12}>
        <Box id="help-me-choose">
          <Typography id="not-sure-text">Not sure which is right for you?</Typography>
          <Button onClick={() => setIsOpen(true)} size="small" id="btn-help-me-choose">
            Help me choose
          </Button>
        </Box>
      </Grid>
    );
  };
  const handleOutside = (_event: null, reason: string) => {
    if (reason !== "backdropClick") {
      setIsOpen(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Masthead />

      {/* breadcrumbs */}
      <Container sx={{ my: 0, py: 3 }}>
        <SearchBreadcrumbs
          loading={false}
          searchCriteriaGroupTids={[]}
          tids={""}
          searchCriteria={""}
          groupId={templates?.catalogGroup}
          currentProduct={currentProduct}
        />
      </Container>

      <Container
        sx={{
          my: 0,
          py: 3,
          bgcolor: "#fff",
          borderRadius: "4px",
          width: "100%",
        }}>
        <Grid container spacing={3}>
          <Grid
            item
            md={3}
            sx={{
              display: { xs: "none", md: "block " },
            }}>
            {isLoading ? (
              <SearchSidebarLoader />
            ) : (
              <>
                <Box display="flex" justifyContent="space-between" sx={{ pl: 2, pr: 4, mb: 2 }}>
                  <Typography fontWeight="bold">Filter and Refine</Typography>
                  {isClearAll() && (
                    <Button
                      style={{
                        border: "none",
                        background: "none",
                        color: "blue",
                      }}
                      size="small"
                      onClick={handleClearAll}>
                      Clear All
                    </Button>
                  )}
                </Box>
                {templates?.attributes.map(attribute => {
                  return <TemplateGroup attribute={attribute} />;
                })}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ px: 2, width: "100%" }}>
              <SearchForm />
            </Box>
            {isLoading ? (
              <SearchResultLoader />
            ) : (
              <Box sx={{ my: 2, px: 2 }}>
                <Box sx={{ mb: 2 }}>
                  <Chips />
                  <Box sx={{ mb: 2 }} />
                  <Results />
                </Box>

                <Grid container direction={"row"} justifyContent={"space-between"} spacing={3}>
                  {products
                    ?.sort((a, b) => {
                      return a.familySelectorOrderId - b.familySelectorOrderId;
                    })
                    .map((product: CatalogTemplate, index: number) => (
                      <>
                        <Grid key={index} item xs={12} md={6} xl={3}>
                          <ButtonBase
                            component={Link}
                            to={`${ROUTES.SEARCH}?tids=${product.templateId}`}
                            sx={{
                              width: "100%",
                            }}>
                            <TeaserCard
                              raVariant="vertical"
                              hover={false}
                              sx={{
                                p: 2,
                                height: cardHeight,
                                justifyContent: "space-between",
                                width: "100%",
                              }}>
                              <Box>
                                <Box display="flex" justifyContent="flex-end">
                                  <Cost cost={product.cost} />
                                  <Box sx={{ mr: 2 }} />
                                  <Features features={product.features} />
                                </Box>
                                <CardMedia
                                  component="img"
                                  sx={{
                                    height: "120px",
                                    backgroundSize: "contain",
                                    objectFit: "contain",
                                    margin: "auto",
                                    padding: "10px",
                                  }}
                                  image={documentUrl(product.photo)}
                                  onError={defaultImageOnError}
                                  alt={`${product.title}_photo`}
                                />
                                <TeaserCardTitle
                                  sx={{
                                    fontSize: "20px",
                                    lineHeight: "28px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    minHeight: "56px",
                                  }}>
                                  {product.title}
                                </TeaserCardTitle>
                                <TeaserCardDescription>
                                  <List>
                                    {product.webFeatures.map((val, index) => {
                                      return <EllipsisTooltip tooltip={val} />;
                                    })}
                                  </List>
                                </TeaserCardDescription>
                              </Box>
                              <FeatureFlag feature={FEATURES.PI26_BP02_CONFIGURATOR_ENABLED}>
                                <TeaserCardActions sx={{ mb: 0 }}>
                                  <Button
                                    sx={{ marginBottom: "5px" }}
                                    variant="outlined"
                                    fullWidth
                                    onClick={e => {
                                      e.preventDefault();
                                      navigate(`${ROUTES.CONFIGURATOR}?tids=${product.templateId}`);
                                    }}>
                                    Configure
                                  </Button>
                                </TeaserCardActions>
                              </FeatureFlag>
                            </TeaserCard>
                          </ButtonBase>
                        </Grid>
                        <FeatureFlag feature={FEATURES.PI26_BP09_PRODUCT_ASSISTANT_MODAL}>
                          {checkForHelpMeChooseAddBtn(index) && <HelpMeChoose />}
                        </FeatureFlag>
                      </>
                    ))}
                </Grid>
                <PageSelector
                  handleChangePageClick={(event: React.ChangeEvent<unknown>, newPage: number) => setPage(newPage)}
                  handleChangePageSelect={(event: SelectChangeEvent) => setPage(Number(event.target.value))}
                  handleChangeProductsPerPage={(event: SelectChangeEvent) => setNumberOfResultsPerPage(Number(event.target.value))}
                  totalCount={products?.length || 0}
                  totalPages={products ? Math.ceil(products.length / numberOfResultsPerPage) : 0}
                  pageNumber={page}
                  numberOfResultsPerPage={numberOfResultsPerPage}
                  rowsPerPageOptions={[25, 50, 100]}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <ProductAssistantTool isOpen={isOpen} handleOutside={handleOutside} handleClose={handleClose} />
    </>
  );
};

interface TemplateGroupProps {
  attribute: TemplateAttribute;
  templates: PostTemplateAttributesResponse | null;
  setTemplates: (templates: PostTemplateAttributesResponse) => void;
  catalogGroupId: string;
  fetchTemplateAttributeData: (catalogGroupId: string, templates: PostTemplateAttributesResponse | null) => Promise<PostTemplateAttributesResponse>;
}

const TemplateGroup: React.FC<TemplateGroupProps> = ({ attribute, templates, setTemplates, catalogGroupId, fetchTemplateAttributeData }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleCheck = async (attribute: TemplateAttribute, value: TemplateAttributeValue, checked: boolean) => {
    const newAttributes: TemplateAttribute[] = templates
      ? templates?.attributes.map(_attribute => {
          if (_attribute.id === attribute.id) {
            return {
              ...attribute,
              values: _attribute.values.map(_value => {
                if (_value.name === value.name) {
                  return {
                    ..._value,
                    isSelected: checked,
                  };
                }
                return _value;
              }),
            };
          }
          return _attribute;
        })
      : [];
    if (templates && catalogGroupId) {
      setTemplates(
        await fetchTemplateAttributeData(catalogGroupId, {
          ...templates,
          attributes: newAttributes,
        })
      );
    }
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`group-${attribute.name}-content`}
        id={`group-${attribute.name}-header`}
        style={{ fontWeight: "bold" }}>
        {attribute.name}
      </AccordionSummary>
      <AccordionDetails
        id={`group-${attribute.name}-content`}
        sx={{
          maxHeight: "14rem",
          overflow: "auto",
          padding: "10px",
        }}>
        {attribute.values
          .filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((value: TemplateAttributeValue, index: number) => {
            return (
              <FormControl key={index} fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => handleCheck(attribute, value, e.target.checked)}
                      checked={value.isSelected}
                      disabled={!value.isSelectable}
                      sx={{ padding: "5px" }}
                    />
                  }
                  label={
                    <Box display="flex" style={{ fontSize: ".9rem" }}>
                      <Typography color="black">{value.name}</Typography>
                    </Box>
                  }
                />
              </FormControl>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

export default FamilySearch;
