import { CatalogProduct } from "platform-services";
import config from "../config";
import { TypedConfig } from "../interfaces/TypedConfig";
import defaultImage from "../assets/Image_Unavailable.svg";

const typedConfig = config as unknown as TypedConfig;

const findNestedProduct = (
  productList: Array<CatalogProduct> | undefined,
  catalogGroupId: string,
  setBreadcrumb?: Function
): CatalogProduct | undefined => {
  if (productList)
    for (let i = 0; i < productList.length; i++) {
      const product = productList[i];
      if (product.catalogGroupId === catalogGroupId) {
        return product;
      }

      const nestedProduct: CatalogProduct | undefined = findNestedProduct(product.childGroups, catalogGroupId);
      if (nestedProduct) {
        if (setBreadcrumb) setBreadcrumb((breadcrumb: Array<CatalogProduct>) => [...breadcrumb, product]);

        return nestedProduct;
      }
    }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const documentUrl = (photo: string) => {
  if (!photo || photo.length === 0) return defaultImage;
  return photo.indexOf("http") >= 0 ? photo : `${typedConfig.DOCUMENT_HOST_LOCATION}${photo}`;
};

const defaultImageOnError = ({ currentTarget }: { currentTarget: EventTarget & HTMLImageElement }) => {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = defaultImage;
};

export { findNestedProduct, scrollToTop, documentUrl, defaultImageOnError };
